import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractEndpoints } from './abstract.endpoints';

const VERIFY_URL = '{apiUrl}/fleets/demonstrations/verifications';

export interface IValidationPostDto {
  clientId: string;
  phoneNumber?: string;
  email?: string;
}

@Injectable({ providedIn: 'root' })
export class VerificationEndpoints extends AbstractEndpoints {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public verify(postData: IValidationPostDto): Observable<void> {
    return this.httpClient.post<void>(this.createUrl(VERIFY_URL), postData);
  }
}
