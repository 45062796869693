import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';

import { HintModule } from './shared/components/hint/hint.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/auth/auth-interceptor';
import { NgxMaskModule } from 'ngx-mask';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { WINDOW_PROVIDERS } from './shared/components/hint/services/window.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { LandingPageComponent } from './view/landing-page/landing-page.component';
import { CommonLayoutModule } from './shared/layouts/common-layout/common-layout.module';
import { AppInputModule } from './shared/forms/input/input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormErrorModule } from './shared/forms/form-error/form-error.module';
import { MarkTouchedIfLengthGreaterThanModule } from './shared/directives/mark-touched-if-length-greater-than/mark-touched-if-length-greater-than.module';
import { BlockuiLoadingModule } from './shared/blockui-loading/blockui-loading.module';

@NgModule({
  declarations: [AppComponent, LandingPageComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    HintModule.forRoot(),
    NgxMaskModule.forRoot(),
    RecaptchaV3Module,
    CommonLayoutModule,
    AppInputModule,
    ReactiveFormsModule,
    FormErrorModule,
    BlockuiLoadingModule,
    MarkTouchedIfLengthGreaterThanModule,
  ],
  providers: [
    WINDOW_PROVIDERS,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaKey },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
