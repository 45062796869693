<app-common-layout>
  <app-blockui-loading *ngIf="isLoading"></app-blockui-loading>
  <div class="container">
    <div class="top-section">
      <div class="title-desc">
        Want to see how it works, without hassle? Check out the #1 GPS Tracking product in seconds! You'll see it all.
        Set up geofences. Send location. See maintenance features. Try it for free before you buy it.
      </div>
      <h1 class="title">Start your virtual demo</h1>
    </div>
    <div class="contact-form">
      <div class="form-title">Contact information</div>
      <form [formGroup]="orderDemoForm">
        <app-input-wrapper id="inputFirstName">
          <input
            appInputCtrl
            [appMarkTouchedIfLengthGreaterThan]="maxLengthList.firstName"
            formControlName="firstName"
            [maxlength]="maxLengthList.firstName"
            class="app-input"
            type="text"
            name="given-name"
            placeholder="First name"
          />
          <div class="input-error">
            <div appFormError="firstName" errorName="required">Please enter your first name.</div>
            <div appFormError="firstName" errorName="maxlength">First name is too long (maximum 50 characters).</div>
          </div>
        </app-input-wrapper>

        <app-input-wrapper id="inputLastName">
          <input
            appInputCtrl
            [appMarkTouchedIfLengthGreaterThan]="maxLengthList.lastName"
            formControlName="lastName"
            [maxlength]="maxLengthList.lastName"
            class="app-input"
            type="text"
            name="lname"
            placeholder="Last name"
          />
          <div class="input-error">
            <div appFormError="lastName" errorName="required">Please enter your last name.</div>
            <div appFormError="lastName" errorName="maxlength">Last name is too long (maximum 50 characters).</div>
          </div>
        </app-input-wrapper>

        <app-input-wrapper id="inputBusiness">
          <input
            appInputCtrl
            [appMarkTouchedIfLengthGreaterThan]="maxLengthList.business"
            formControlName="business"
            [maxlength]="maxLengthList.business"
            class="app-input"
            type="text"
            name="lname"
            placeholder="Business name"
          />
          <div class="input-error">
            <div appFormError="business" errorName="required">Please enter your business name.</div>
            <div appFormError="business" errorName="maxlength">Business name is too long (maximum 50 characters).</div>
          </div>
        </app-input-wrapper>

        <app-input-wrapper id="inputEmail">
          <input
            #emailInput
            appInputCtrl
            name="email"
            class="app-input"
            formControlName="email"
            type="email"
            placeholder="Business email"
          />
          <div *ngIf="orderDemoForm.get('email')?.status !== 'PENDING'; else validatingEmailTpl" class="input-error">
            <div appFormError="email" errorName="required">Please enter your business email.</div>
            <div appFormError="email" errorName="email">Please enter a valid email address.</div>
            <div appFormError="email" errorName="emailAsync">
              {{ orderDemoForm.get('email')?.errors?.emailAsync }}
            </div>
          </div>
          <ng-template #validatingEmailTpl>
            <div class="input-validation-in-progress">
              Validating email address...
            </div>
          </ng-template>
        </app-input-wrapper>

        <app-input-wrapper id="inputConfirmEmail">
          <input
            #confirmEmailInput
            appInputCtrl
            name="confirmEmail"
            class="app-input"
            formControlName="confirmEmail"
            type="email"
            placeholder="Confirm business email"
          />
          <div class="input-error">
            <div appFormError="confirmEmail" errorName="required">Please confirm your business email.</div>
            <div appFormError="confirmEmail" errorName="emailsNotMatching">
              Please re-enter your business email address here.
            </div>
          </div>
        </app-input-wrapper>

        <app-input-wrapper id="inputPhone">
          <input
            #phoneInput
            appInputCtrl
            name="phone"
            class="app-input phone-input"
            inputmode="numeric"
            type="text"
            placeholder="Mobile number"
            formControlName="phone"
            (ngModelChange)="onPhoneInputChange($event)"
          />
          <span class="area-code-icon"></span>
          <div *ngIf="orderDemoForm.get('phone')?.status !== 'PENDING'; else validatingPhoneTpl" class="input-error">
            <div appFormError="phone" errorName="required">Please enter your mobile number.</div>
            <div appFormError="phone" errorName="minlength">Phone number is too short</div>
            <div appFormError="phone" errorName="phoneAsync">
              {{ orderDemoForm.get('phone')?.errors?.phoneAsync }}
            </div>
          </div>
          <ng-template #validatingPhoneTpl>
            <div class="input-validation-in-progress">
              Validating phone number...
            </div>
          </ng-template>
        </app-input-wrapper>
      </form>

      <div class="privacy-msg">
        All fields required. We will not share your information with anyone else:
        <a target="_blank" href="https://forcebymojio.com/privacy/">Mojio Privacy Policy</a>
      </div>

      <div class="btn-order">
        <button class="btn-primary" [disabled]="!orderDemoForm.valid || reCaptchaFailed" (click)="onOrderDemoPressed()">
          Launch Virtual Demo
        </button>
      </div>
      <!-- RECAPTCHA FAIL COUNTDOWN -->
      <!-- <div class="error-msg" *ngIf="reCaptchaFailed">Please try again in {{ counter$ | async }} sec</div> -->
      <div class="error-msg" *ngIf="!reCaptchaFailed && errorMsg">{{ errorMsg }}<br />Please try again.</div>
    </div>
    <img class="feature-img" src="/assets/images/hand-large.png" />
  </div>
</app-common-layout>
