import { AbstractControl } from '@angular/forms';

// tslint:disable-next-line:function-name
export function MatchEmailValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (!control.parent) {
    return null;
  }
  const emailVal = control.parent.get('email')?.value?.toLowerCase();
  const confirmEmailVal = control.parent.get('confirmEmail')?.value?.toLowerCase();

  if (emailVal && confirmEmailVal && emailVal !== confirmEmailVal) {
    return { emailsNotMatching: true };
  }
  return null;
}
