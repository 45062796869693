import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import {
  RegistrationEndpoints,
  IRegistrationPostResDto,
  IRegistrationPostReqDto,
} from '../endpoints/registration.endpoints';

export interface IJsonWebToken {
  exp: number;
  iss: string;
  client_id: string;
  idp: string;
  name: string;
  tenant: string;
  organization: string;
  given_name: string;
  family_name: string;
  email: string;
  phone_number: number;
  fleetrole: string;
  scope: string[];
}
@Injectable({ providedIn: 'root' })
export class RegistrationLoader {
  constructor(private endpoints: RegistrationEndpoints) {}

  public register(params: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    business: string;
  }): Observable<string> {
    const { firstName, lastName, email, phone, business } = params;
    const req: IRegistrationPostReqDto = {
      clientId: this.endpoints.getClientId(),
      organizationName: business,
      owner: {
        firstName,
        lastName,
        email,
        phone,
        temporaryPassword: 'forcefleet',
      },
    };

    return this.endpoints.register(req).pipe(
      map(({ accessToken }: IRegistrationPostResDto) => {
        return this.generateForceDemoUri(accessToken);
      }),
      catchError((e) => throwError(e.error && e.error.Message)),
    );
  }

  private generateForceDemoUri(accessToken: string): string {
    const { exp: expiry, scope } = this.parseJwt(accessToken);
    const url = `${environment.forceUrl}/dashboard#access_token=${accessToken}&token_type=bearer&expires_in=${expiry}&scope=full`;
    return url;
  }

  private parseJwt(token: string): IJsonWebToken {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      throw new Error('Error parsing JWT');
    }
  }
}
