// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from './ienvironment';

export const environment: IEnvironment = {
  production: false,
  clientId: 'd447f0aa-ed95-4129-81d0-ed9b87485d77',
  apiUrl: 'https://force-us-phoenix-staging-api.moj.io',
  forceUrl: 'https://force-us-staging.moj.io',
  authToken: '831a1895-4526-40bb-9a92-a3387b0b4f3a',
  reCaptchaKey: '6LcKXMcjAAAAAAOLe8TNmx_OYAY32HHREuJxZmcE',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
