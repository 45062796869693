import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailValidator } from '../../core/forms/validators/email.validator';
import { AsyncValidatorsService } from '../../core/forms/async-validators/async-validators.service';
import { DOCUMENT } from '@angular/common';
// import { ReCaptchaV3Service } from 'ng-recaptcha';
import { RequiredAfterTrimmingValidator } from '../../core/forms/validators/required-after-trimming.validator';
import { Observable, of, Subject, timer } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, share, take, takeUntil } from 'rxjs/operators';
import { RegistrationLoader } from 'src/app/core/loaders/registration.loader';
import { MatchEmailValidator } from 'src/app/core/forms/validators/email-match.validator';
import { MaskApplierService } from 'ngx-mask';
import { ConversionsApi } from 'src/app/core/loaders/conversion-api.loader';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  private static readonly CAPTCHA_ACTION_NEW_ORDER = 'NewDemoAccount';
  private readonly onDestroy$: Subject<void> = new Subject<void>();

  @ViewChild('phoneInput') inputPhoneRef!: ElementRef<HTMLInputElement>;

  public orderDemoForm!: FormGroup;
  public errorMsg = '';
  public isLoading = false;
  public isUpdatingPhone = false;
  public maxLengthList = {
    firstName: 50,
    lastName: 50,
    business: 50,
  };

  // reCaptcha-specific
  public counter$: Observable<number> = of(0);
  public reCaptchaFailed = false;
  public retryCount = 10;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private maskService: MaskApplierService,
    private asyncValidators: AsyncValidatorsService,
    // private recaptchaV3Service: ReCaptchaV3Service,
    private registrationLoader: RegistrationLoader,
    private conversionsApiLoader: ConversionsApi,
  ) {}

  public ngOnInit(): void {
    this.orderDemoForm = new FormGroup({
      firstName: new FormControl('', [
        RequiredAfterTrimmingValidator,
        Validators.maxLength(this.maxLengthList.firstName),
      ]),
      lastName: new FormControl('', [
        RequiredAfterTrimmingValidator,
        Validators.maxLength(this.maxLengthList.lastName),
      ]),
      business: new FormControl('', [
        RequiredAfterTrimmingValidator,
        Validators.maxLength(this.maxLengthList.business),
      ]),
      email: new FormControl('', {
        validators: [Validators.required, EmailValidator],
        asyncValidators: this.asyncValidators.emailValidator(),
      }),
      confirmEmail: new FormControl('', {
        validators: [Validators.required, EmailValidator, MatchEmailValidator],
      }),
      phone: new FormControl('', {
        validators: [Validators.required, Validators.minLength(10)],
        asyncValidators: this.asyncValidators.phoneValidator('1'),
      }),
    });
    const [emailFormControl, confirmEmailFormControl, phoneFormControl] = [
      this.orderDemoForm.get('email'),
      this.orderDemoForm.get('confirmEmail'),
      this.orderDemoForm.get('phone'),
    ];

    emailFormControl?.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      confirmEmailFormControl?.updateValueAndValidity();
    });

    phoneFormControl?.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        distinctUntilChanged(),
        map((value) => value.replace(/[^\d]/g, '')),
        filter((value) => value.length === 10 && phoneFormControl?.status === 'PENDING' && !phoneFormControl?.touched),
      )
      .subscribe(() => {
        phoneFormControl?.markAsTouched();
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public onPhoneInputChange(value: string): void {
    if (this.isUpdatingPhone) {
      return;
    }
    // truncate (+1) extension at start of phone number if it exists
    value = value.replace(/[^\d]/g, '');
    value = value.length === 11 && value[0] === '1' ? value.substring(1) : value;
    const formattedValue = this.maskService.applyMask(value, '000-000-0000');

    this.isUpdatingPhone = true;
    this.orderDemoForm.get('phone')?.setValue(value, { emitEvent: false }); // set form control value
    this.inputPhoneRef.nativeElement.value = formattedValue; // set input value
    this.isUpdatingPhone = false;
  }

  public onOrderDemoPressed(): void {
    const { firstName, lastName, phone, email, business } = this.orderDemoForm.value;
    this.isLoading = true;

    this.createConversionsApiEvent(email, phone);
    this.registrationLoader
      .register({
        firstName,
        lastName,
        phone: '1' + phone,
        email,
        business,
      })
      .pipe(first())
      .subscribe(
        (url: string) => (location.href = url),
        (err: string) => {
          this.errorMsg = err;
          this.isLoading = false;
          this.orderDemoForm.reset();
          console.log(err);
        },
      );
    //
    // this.generateReCaptchaToken()
    //   .pipe(take(1))
    //   .subscribe((recaptchaToken: string) => {
    //     // TODO
    //   });
  }

  private createConversionsApiEvent(email: string, phone: string): void {
    this.conversionsApiLoader
      .createEvent(email, phone)
      .pipe(take(1))
      .subscribe({
        error: (err) => console.error(err),
      });
  }

  // private generateReCaptchaToken(): Observable<string> {
  //   return this.recaptchaV3Service.execute(LandingPageComponent.CAPTCHA_ACTION_NEW_ORDER).pipe(
  //     take(1),
  //     map((token: string) => token),
  //     catchError((error) => {
  //       console.log(`ReCaptcha error generating token:`, error);
  //       return of('');
  //     }),
  //   );
  // }

  // private startRetryCounter(): void {
  //   this.reCaptchaFailed = true;
  //   this.retryCount = 10;
  //   this.counter$ = timer(0, this.retryCount * 100).pipe(
  //     take(this.retryCount),
  //     map(() => {
  //       if (this.retryCount <= 1) {
  //         this.reCaptchaFailed = false;
  //       }
  //       return --this.retryCount;
  //     }),
  //     share(),
  //   );
  // }
}
