const DEFAULT_PARSE_REGEXP = /\{([^\}]+)?\}/g;
/**
 * this is a primitive template engine that can iterpolate var inside braces {varName}
 */
export function interpolateStr(tpl: string, data: { [propName: string]: any }, parseRegexp?: RegExp): string {
  const re = parseRegexp || DEFAULT_PARSE_REGEXP;
  let resultTpl = tpl;
  let match;
  // tslint:disable-next-line: no-conditional-assignment
  while ((match = re.exec(resultTpl))) {
    resultTpl = resultTpl.replace(match[0], data[match[1]] || '');
  }
  return resultTpl;
}

export async function encryptSha256(message: string): Promise<string> {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}
