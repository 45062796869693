import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractEndpoints } from './abstract.endpoints';

const REGISTRATION_URL = '{apiUrl}/fleets/demonstrations/registrations';

export interface IRegistrationPostReqDto {
  clientId: string;
  organizationName: string;
  owner: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    temporaryPassword: string;
  };
}

export interface IRegistrationPostResDto {
  accessToken: string;
}

@Injectable({ providedIn: 'root' })
export class RegistrationEndpoints extends AbstractEndpoints {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public register(postData: IRegistrationPostReqDto): Observable<IRegistrationPostResDto> {
    return this.httpClient.post<IRegistrationPostResDto>(this.createUrl(REGISTRATION_URL), postData);
  }
}
