import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'demo-signup';

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public ngOnInit(): void {
    this.setRecaptchaScript();
  }

  private setRecaptchaScript(): void {
    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${environment.reCaptchaKey}`;
    const head = this.document.getElementsByTagName('head')[0];
    head.appendChild(script);
  }
}
